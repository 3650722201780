import { template as template_5328673ac1ad4560afca657123528c86 } from "@ember/template-compiler";
const FKText = template_5328673ac1ad4560afca657123528c86(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
