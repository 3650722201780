import { template as template_5a3af9b69db64aceaa04f4bd909b1ce0 } from "@ember/template-compiler";
const WelcomeHeader = template_5a3af9b69db64aceaa04f4bd909b1ce0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
