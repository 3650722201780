import { template as template_d803b16e4aaa439daaf7846c5b7652d0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d803b16e4aaa439daaf7846c5b7652d0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
