import { template as template_623277270c36406bbd3c53c4d4bcebdc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_623277270c36406bbd3c53c4d4bcebdc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
